<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searParkRecordList">
        <el-form
          :inline="true"
          ref="searchWrapper"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
          :rules="rule"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.region')">
                <el-select
                  v-model.trim="formInline.city"
                  filterable
                  size="15"
                  placeholder="请选择"
                  @change="allAreaCity"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="area.name"
                    :value="area.cityId"
                    :key="area.cityId"
                    v-for="area in AllCity"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.region')">
                <el-select
                  v-model.trim="formInline.area"
                  filterable
                  @change="handleArea"
                  size="15"
                  placeholder="请选择"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="area.name"
                    :value="area.code"
                    :key="area.code"
                    v-for="area in areaList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.park_name')">
                <el-autocomplete
                  v-model="parkFilingId"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  @select="handleSelect"
                  value-key="parkFilingName"
                  :trigger-on-focus="false"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="运营企业" prop="operationId">
                <el-select v-model.trim="formInline.operationId" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="停车场类型">
                <el-select
                  v-model.trim="formInline.parkFilingType"
                  filterable
                  placeholder=""
                  size="10"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="park.desc"
                    :value="park.code"
                    :key="park.code"
                    v-for="park in parkFilingTypeList"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.query']"
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searParkRecordList();
                "
                :loading="loading"
                >{{ $t('button.search') }}</AuthorityComponent
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table  v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column label="操作" align="center" width="80">
            <template slot-scope="scope">
              <AuthorityComponent
                ComponentName="el-dropdown"
                align="center"
                @command="handleCommand($event, scope.row)"
                width="80"
              >
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <!-- v-if="$route.meta.authority.button.detail" -->
                  <el-dropdown-item command="a">详细信息</el-dropdown-item>
                  <!-- v-if="$route.meta.authority.button.edit" -->
                  <el-dropdown-item command="b">修改</el-dropdown-item>
                </el-dropdown-menu>
              </AuthorityComponent>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, setIndex, exportExcelNew } from "@/common/js/public.js";
export default {
  name: "ParkFilingConf",
  data() {
    let date1 = new Date();
    date1.setHours(0);
    date1.setMinutes(0);
    date1.setSeconds(0);
    date1.setTime(date1.getTime() - 3600 * 1000 * 24 * 30);
    let startTime = date1;
    let date = new Date();
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    let endTime = date;
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 11) {
        callback();
      } else if (len > 11) {
        callback(new Error("最多输入十一个字符"));
      }
    };
    let checkPhone = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value) && value) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    return {
      flag: false,
      plateNumber: "",
      parkFilingId: "",
      rowData: {},
      infoSize: 4,
      parkFilingName: "",
      isShow: false,
      dialogVisible: false,
      infoDetail: {},
      radio3: "停车记录信息",
      isPublic: true,
      index: 0,
      total: 0,
      total1: 0,
      entryPic: [],
      exitPic: [],
      date1: startTime,
      date2: endTime,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      AllCity: [],
      areaList2: [],
      parkList: [],
      parkFilingTypeList: [],
      merchantList: [],
      payTypeList: [],
      berthList: [],
      ExitTypeList: [],
      tenantList: [],
      roleList: [],
      feeDetail: {}, // 费用明细
      rule: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
        mobile: [
          {
            validator: checkPhone,
            trigger: "blur",
          },
        ],
      },
      tableCols: [
        // {
        //   prop: "parkFilingCode",
        //   label: "停车场编号",
        //   width: ""
        // },
        {
          prop: "parkFilingName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "operationalBerthNumber",
          label: "运营泊位数",
          width: "",
        },
        {
          prop: "operationName",
          label: "运营企业",
          width: "",
        },
        {
          prop: "area",
          label: this.$t("list.region"),
          width: "",
          formatter: (r, c, v) => {
            if (v) return v.substr(v.indexOf("-") + 1);
            return "";
          },
        },
        {
          prop: "parkFilingType",
          label: "停车场类型",
          width: "",
          formatter: (row, column) => {
            if (row.parkFilingType == 1) {
              return "公共停车场";
            } else if (row.parkFilingType == 2) {
              return "专用停车场";
            } else if (row.parkFilingType == 3) {
              return "P+R停车场";
            } else if (row.parkFilingType == 4) {
              return "路侧停车场";
            } else {
              return "";
            }
          },
        },
        {
          prop: "parkStatus",
          label: "停车场状态",
          width: "",
          formatter: (row, column) => {
            if (row.parkStatus == 1) {
              return "生效";
            } else if (row.parkStatus == 2) {
              return "下线";
            } else if (row.parkStatus == 3) {
              return "生效-变更中";
            } else {
              return "下线-变更中";
            }
          },
        },
        {
          prop: "leaseValidity",
          label: "有效期",
        },
      ],
      tableData: [],
      parkDetail: [],
      parkRecordId: 0,
      page: 1,
      size: 15,
      formInline: {
        city: "",
        area: "",
        cityName: "",
        areaName: "",
        parkFilingId: "",
        operationId: "",
        parkFilingType: "",
      },
    };
  },
  methods: {
    handleArea(v) {
      this.formInline.area = v;
      this.formInline.areaName = this.areaList.filter((item) => v == item.code)[0].name;
    },
    resetForm() {
      // this.$refs["searchWrapper"].clearValidate();
      this.formInline = {
        area: "",
        parkFilingId: "",
        operationId: "",
        parkFilingType: "",
        city: "",
      };
      this.parkFilingId = "";
    },
    // timestampToTime(timestamp) {
    //   var date = new Date(timestamp * 1); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    //   var Y = date.getFullYear() + "-";
    //   var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
    //   var D = date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
    //   var h = date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
    //   var m = date.getMinutes() < 10 ? "0" + date.getMinutes() + ":" : date.getMinutes() + ":";
    //   var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    //   return Y + M + D + h + m + s;
    // },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        // 查看
        this.$router.push({
          path: "/recordParkDetails",
          query: data,
        });
      }
      if (cmd == "b") {
        this.getAuditFiling(data);
      }
    },
    getAuditFiling(data) {
      this.$axios
        .get("/acb/2.0/parkFiling/getAuditFiling", {
          data: {
            parkFilingId: data.parkFilingId,
          },
        })
        .then((res) => {
          if (res.value.dealType === 1 || res.value.dealType === 2) {
            // 修改
            this.$router.push({
              path: "/recordParkAdd",
              query: {
                ...data,
                parkFilingOrignId:
                  res.value.dealType === 1 ? res.value.parkFilingId : data.parkFilingId,
                dealType: res.value.dealType,
                parkFilingId: res.value.dealType === 2 ? res.value.parkFilingId : data.parkFilingId,
              },
            });
          } else {
            this.$alert("已存在待审核的修改订单，是否查看", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              // 修改
              this.$router.push({
                path: "/detailsInfoBA",
                query: {
                  ...data,
                  dealType: res.value.dealType,
                  parkFilingId: res.value.parkFilingId,
                },
              });
            });
          }
        });
    },
    /* 运营企业的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    plateNumberSelect(item) {},
    handleSelect(item) {
      this.formInline.parkFilingId = item.parkFilingId;
    },
    onTopClick() {
      window.location.hash = "#order";
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      if (!this.flag) {
        this.searParkRecordList();
      }
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    addPark() {
      this.$router.push({
        path: "/recordParkAdd",
      });
    },
    /* 停车场 */
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      this.$axios
        .get("/acb/2.0/parkFiling/parkFilingName", {
          data: {
            parkFilingNameLike: queryString,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline.parkId = "";
            cb(res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    /* 获取车场类型 */
    getAddOption() {
      this.$axios.get("/acb/2.0/parkFiling/getAddOption").then((res) => {
        if (res.state == 0) {
          this.parkFilingTypeList = res.value.parkFilingType; // 停车场类型
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 出入场类型列表
    getExitType() {
      let opt = {
        url: "/acb/2.0/parkRecord/entryExitType/list",
        method: "get",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.ExitTypeList = res.value;
          } else {
            this.$alert("获取出入场类型失败", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 获取停车场详情图片
    getPic(entryOprNum, exitOprNum) {
      this.entryPic = [];
      this.exitPic = [];
      if (entryOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + entryOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.entryPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
      if (exitOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + exitOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.exitPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
    },
    clearSearchOpt(data) {
      this.parkData();
    },
    // 获取默认情况下的各种下拉列表
    getParkSpinner() {
      this.$axios.get("/acb/2.0/park/spinner").then((res) => {
        for (let i = 0; i < res.value.parkType.length; i++) {
          this.parkType.push(res.value.parkType[i]);
        }
      });
    },
    showLog() {
      if (this.date1 && this.date2) {
        let startTime = dateFormat(this.date1, "yyyy-MM-dd HH:mm:ss");
        let endTime = dateFormat(this.date2, "yyyy-MM-dd HH:mm:ss");
        this.formInline.startTime = startTime || this.date1;
        this.formInline.endTime = endTime || this.date2;
        var time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (this.formInline.plateNumber == "" && time > 31) {
          this.$alert("请缩小搜索范围，您可输入车牌号或搜索时间范围不超过31天");
          return false;
        }
        if (this.formInline.startTime > this.formInline.endTime) {
          this.$alert("开始时间不能大于结束时间");
          return false;
        } else {
          return true;
        }
      } else {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
        this.$alert("时间段不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    // 初始化用户区域下拉列表
    getArea(v) {
      let areaInfo = require("./dataJson/areas.json");
      this.formInline.area = "";
      this.areaList = areaInfo.filter((res) => {
        return res.cityCode == v;
      });
      //      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then(res => {
      //        if (res.value.depth == 3) {
      //          this.areaList = res.value.areaList;
      //          this.formInline.areaId = "";
      //        } else if (res.value.depth == 4) {
      //          this.areaList2 = res.value.areaList;
      //          this.formInline.areaId = "";
      //        }
      //      });
    },
    getAllCity() {
      let AllCityInfo = require("./dataJson/cities.json");
      this.AllCity = AllCityInfo;
    },
    allAreaCity(v) {
      this.getArea(v);
      this.formInline.cityName = this.AllCity.filter((item) => v == item.cityId)[0].name;
      this.formInline.areaName = "";
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/operation/list",
        data: {
          page: 0,
          pageSize: 0,
          companyName: "",
          bearSide: "",
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    searParkRecordList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkFiling/list",
        data: {
          auditStatus: 4, // 审核通过
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          ...this.formInline,
        },
        success: (res) => {
          this.loading = false;
          if (res.state === 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          }
        },
        fail: () => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
  },
  components: {},
  created() {},
  mounted() {
    this.getAllCity();
    this.searParkRecordList();
    this.getTenantList();
    this.getAddOption();
    // this.getArea()
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.conborder
  border-top 1px solid #C0CCDA
  background #FFFFFF
  overflow hidden
  padding 10px
.content
  background #FFFFFF
  overflow hidden

    //margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      border 1px solid #0099FA
      border-radius 4px
      color #0099FA
      padding 8px 13px
      cursor pointer
      .iconfont
        margin-right 14px
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
</style>
